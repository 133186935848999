<template>
  <div>
    <div class="header">
      <div class="header-content">项目测评</div>
    </div>
    <div class="main-container">
      <div class="search-section">
        <el-input
          v-model="searchText"
          placeholder="搜索相关评测试卷"
          style="height: 40px"
          @keyup.enter="handleSearch"
        ></el-input>
      </div>
      <div class="button-group">
        <button
          v-for="item in types"
          :key="item.id"
          @click="selectType(item)"
          :class="{ active: currentType === item.id }"
        >
          {{ item.evaluation_type_name }}
        </button>
      </div>
      <div class="filter-list">
        <div v-for="item in filteredList" :key="item.id" class="filter-item">
          <div class="item-container">
            <img :src="imgsUrl + item.evaluation_image" alt="Evaluation Image" class="item-image" />
            <div class="item-content">
              <div class="item-name">{{ item.evaluation_name }}</div>
              <div class="item-type">
                <span v-for="(typeName, index) in item.evaluation_type_name.split(',')" :key="index">
                  #{{ typeName }}
                </span>
              </div>
              <button class="item-button" @click="jumpInfo(item.id)">立即测评</button>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页组件 -->
      <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          v-model:current-page="currentPage"
          @update:current-page="handlePageChange"
          style="margin-top: 20px; margin-left: 190px"
        ></el-pagination>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { getEvaType, getEvaList } from "../api/evaluation.js";
import { useRouter } from "vue-router";
const router = useRouter();

const searchText = ref("");
const currentType = ref(""); // 默认不选中
const types = ref([]);
const originalList = ref([]);
const currentPage = ref(1);
const total = ref(0);
const imgsUrl='https://admin.syshunying.cn/'

const filteredList = computed(() => {
  if (!Array.isArray(originalList.value)) {
    return [];
  }
  if (!currentType.value) {
    return originalList.value;
  } else {
    return originalList.value.filter(
      (item) => item.evaluation_type_id.includes(currentType.value)
    );
  }
});

const selectType = (type) => {
  if (currentType.value === type.id) {
    currentType.value = "";
  } else {
    currentType.value = type.id;
  }
  getList();
};

const getType = async () => {
  const res = await getEvaType({ pid: 1 });
  if (res && res.data) {
    types.value = res.data;
  }
};

const getList = async () => {
  const params = {
    page: currentPage.value,
    limit: 9,
    keyword: searchText.value,
    evaluation_type_pid: 1,
    evaluation_type_id: currentType.value,
  };
  const res = await getEvaList(params);
  if (res && res.data) {
    originalList.value = res.data.lists || [];
    total.value = res.data.total || 0;
  }
};
// 分页
const handlePageChange = (newPage) => {
  currentPage.value = newPage;
  getList();
};
// 搜索
function handleSearch() {
  getList();
}
// 跳转详情
function jumpInfo(id){
  router.push({ name: "evaluationInfo",query:{id} });
}
onMounted(() => {
  getType();
  getList();
});
</script>
<style scoped>
.header {
  background-color: blue;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* 不限制宽度 */
  color: white;
  font-size: 24px;
}

.main-container {
  width: 800px;
  margin: 0 auto;
  
}

.search-section {
  margin: 20px auto;
  width: 800px; /* 适当调整输入框宽度 */
}

.el-input {
  width: 800px;
}

.button-group {
  width: 800px;
  display: flex;
  justify-content: space-around; /* 均匀分布按钮 */
  margin-bottom: 20px;
}

.button-group button {
  width: 76px;
    height: 30px;
    border-radius: 38%;
  font-size: 14px;
  border: 2px solid #ccc;
  background-color: #f0f0f0;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button-group button.active {
  background-color: #4caf50;
  color: white;
  border-color: #4caf50;
}

.filter-list {
  display: flex;
  flex-wrap: wrap;
}

.filter-item {
  width: calc(33.333% - 20px); /* 三列布局，每列间隔20px */
  margin: 10px;
  box-sizing: border-box;
}

.item-container {
  width:300px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
}

.item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
}

.item-content {
  margin-left: 10px;
}

.item-name {
  font-size: 16px;
  font-weight: bold;
}

.item-type {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

.item-type span {
  margin-right: 5px;
}

.item-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #409eff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.item-button:hover {
  background-color: #3b8ff0;
}
</style>
