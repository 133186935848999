import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import firstPage from '../views/firstPage.vue';
import achievePage from '../views/achievePage.vue';
import myPage from '../views/myPage.vue';
import evaluationPage from '../views/evaluationPage.vue'
import evaluationInfo from '../views/evaluationInfo.vue'
import registerPage from '../views/registerPage.vue'
import companyButler from '../views/companyButler.vue'
import companyDetail from '../views/companyDetail.vue'
import evaTest from '../views/evaTest.vue'
const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/LoginView',
    name: 'LoginView',
    component: LoginView
  }
  ,
  {
    path: '/firstPage',
    name: 'firstPage',
    component: firstPage
  }
  ,
  {
    path: '/achievePage',
    name: 'achievePage',
    component: achievePage
  }
  ,
  {
    path: '/myPage',
    name: 'myPage',
    component: myPage
  },
  {
    path: '/evaluationPage',
    name: 'evaluationPage',
    component: evaluationPage
  },
  {
    path: '/evaluationInfo',
    name: 'evaluationInfo',
    component: evaluationInfo
  },
  {
    path: '/registerPage',
    name: 'registerPage',
    component: registerPage
  },
  {
    path: '/companyButler',
    name: 'companyButler',
    component: companyButler
  },
  {
    path: '/companyDetail',
    name: 'companyDetail',
    component: companyDetail
  },
  {
    path: '/evaTest',
    name: 'evaTest',
    component: evaTest
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;