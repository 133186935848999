import http from './axios';
// 测评类型
export function getEvaType(data) {
    return http.post('/frontipad/evaluation_type/list', data);
}
// 测评列表
export function getEvaList(data) {
    return http.post('/frontipad/evaluation/list', data);
}
// 测评详情
export function getEvaDetail(data) {
    return http.post('/frontipad/evaluation/info', data);
}
// 测评注册
export function registerEva(data) {
    return http.post('/frontipad/evaluation/companyregister', data);
}
// 测评提交
export function evaSubmit(data) {
    return http.post('/frontipad/evaluation/submit', data);
}