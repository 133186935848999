<template>
  <div>
    <div class="header">
      <div class="header-content">测评详情</div>
    </div>
    <div class="main-container">
      <div class="evaluation-header">
        <img :src="urls" alt="Evaluation Image" class="item-image" />
        <h2 class="evaluation-name">{{ evaluationData.evaluation_name }}</h2>
        <div class="evaluation-type">{{ evaluationData.evaluation_type_name }}</div>
        
      </div>
      <div class="info-line">
        {{ length }}道精选题目 + 专业测评报告
      </div>
      <div class="big-box">
        <div class="section">
          <h3>测评简介:</h3>
          <p>{{ evaluationData.evaluation_intro }}</p>
        </div>
        <div class="section">
          <h3>测评说明:</h3>
          <p>{{ evaluationData.evaluation_explain }}</p>
        </div>
        <button class="immediate-assessment" @click="jumpTest(evaluationData.id)">立即测评</button>
      </div>
     
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

import { getEvaDetail } from "../api/evaluation.js";
const router = useRouter();

const route = useRoute();
const id = ref(route.query.id);
const imgBaseUrl = 'https://admin.syshunying.cn/';
const urls=ref('')
const evaluationData = ref({});
const length=ref(0)
async function getDetail() {
  if (id.value) {
    const res = await getEvaDetail({ id: id.value });
    if (res && res.data) {
      evaluationData.value = res.data;
      
      length.value=evaluationData.value.question.length
      urls.value = imgBaseUrl + res.data.evaluation_image;
    }
  }
}
function jumpTest(id) {
  router.push({ name: "evaTest",query:{id} });
}
onMounted(() => {
  getDetail();
});
</script>
<style scoped>
.header {
  background-color: blue;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  font-size: 24px;
}

.main-container {
  width: 800px;
  margin: 20px auto;
}

.evaluation-header {
  margin-top: 20px;
}

.evaluation-name {
  font-size: 28px;
  color: #333;
  margin-bottom: 10px;
}

.evaluation-type {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.info-line {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.big-box {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.section h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

.section p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

.immediate-assessment {
  background-color: #409EFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
  margin: 0 auto;
}

.immediate-assessment:hover {
  background-color: #3b8ff0;
}
.item-image {
  width: 800px;
  height: 280px;
  object-fit: cover;
  border-radius: 5px;
}
</style>