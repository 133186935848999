<template>
  <div class="icon-section">
    <div class="icon-item" @click="gotoHome">
      <img src="../assets/firstPage.png" alt="首页" class="el-icon" />
      <span>首页</span>
    </div>
    <div class="icon-item" @click="gotoAchieve">
      <img src="../assets/achievement.png" alt="技术成果库" class="el-icon" />
      <span>技术成果库</span>
    </div>
    <div class="icon-item" @click="gotoProjectEvaluation">
      <img src="../assets/eva.png" alt="项目测评" class="el-icon" />
      <span>项目测评</span>
    </div>
    <div class="icon-item" @click="gotoMine">
      <img src="../assets/my.png" alt="个人中心" class="el-icon" />
      <span>个人中心</span>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

function gotoHome() {
  router.push({ name: "firstPage" });
}
function gotoAchieve() {
  router.push({ name: "achievePage" });
}
function gotoMine() {
  router.push({ name: "myPage" });
}
// 项目测评
const gotoProjectEvaluation = () => {
  router.push({ name: "evaluationPage" });
};
</script>
<style scoped>
.icon-section {
  width: 800px; /* 与.tabs-container宽度一致 */
  margin: 0 auto; /* 水平居中 */
  display: flex;
  justify-content: space-around; /* 均匀分布 */
  margin-top: 20px; /* 与上面的内容分隔 */
}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center; /* 图标和文字垂直居中 */
}

.icon-item span {
  font-size: 14px;
  margin-top: 5px;
}

.icon-item .el-icon {
  font-size: 24px; /* 根据需要调整 */
}
</style>
