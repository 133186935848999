<template>
  <div>
    <div class="header">
      <div class="header-content">企业管家</div>
    </div>
    <div class="container">
      <div class="box-group">
        <div class="small-box">
          <img
            src="https://www.syshunying.cn/xiaochengxu/newversion/img/1026/company.png"
            class="circle-image"
          />
          <div class="number" style="color: #cda30f">3</div>
          <div class="text" style="color: #cda30f">科技型中小企业</div>
        </div>
        <div class="small-box">
          <img
            src="https://www.syshunying.cn/xiaochengxu/newversion/img/1026/newadd.png"
            class="circle-image"
          />
          <div class="number" style="color: #aebd27">0</div>
          <div class="text" style="color: #aebd27">高新技术企业</div>
        </div>
        <div class="small-box">
          <img
            src="https://www.syshunying.cn/xiaochengxu/newversion/img/1026/service.png"
            class="circle-image"
          />
          <div class="number" style="color: #4738ba">2</div>
          <div class="text" style="color: #4738ba">专精特新企业</div>
        </div>
        <div class="small-box">
          <img
            src="https://www.syshunying.cn/xiaochengxu/newversion/img/1026/cooperation.png"
            class="circle-image"
          />
          <div class="number" style="color: #2441a2">2</div>
          <div class="text" style="color: #2441a2">毕业企业</div>
        </div>
      </div>
      <div class="large-box">
        <div style="display: flex">
          <img
            src="../assets/achLogo.png"
            style="height: 40px; margin-top: 16px"
          />
          <h2 style="margin-left: 4px">企业服务列表</h2>
        </div>

        <div
          class="list-item"
          v-for="item in companyList"
          :key="item.id"
          @click="jumpDetail"
        >
          <div class="header-section">
            <h3>{{ item.company_name }}</h3>
          </div>
          <div style="display: flex">
            <p>负责人: {{ item.contacter_name ? item.contacter_name : " 暂无 "}}</p>

            <p style="margin-left:30px">
              联系电话:
              {{ item.contacter_phone ? item.contacter_phone : " 暂无 " }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页组件 -->
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      v-model:current-page="currentPage"
      @update:current-page="handlePageChange"
      style="margin-top: 20px; margin-left: 46%"
    ></el-pagination>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { getCompanyList } from "../api/company.js";
import { useRouter } from "vue-router";
const router = useRouter();

const total = ref(0);
const currentPage = ref(1);
const companyList = ref([]);
async function fetCompanyList() {
  const res = await getCompanyList({
    keyword: "",
    page: currentPage.value,
    limit: 10,
    month: "",
    start_time: "",
    end_time: "",
  });
  companyList.value = res.data.lists;
  total.value = res.data.total;
}

onMounted(() => {
  fetCompanyList();
});
// 分页
const handlePageChange = (newPage) => {
  currentPage.value = newPage;
  fetCompanyList();
};
// 跳转测评注册
const jumpDetail = () => {
  router.push({ name: "companyDetail" });
};
</script>
<style scoped>
.header {
  background-color: blue;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-content {
  color: white;
  font-size: 24px;
}
.container {
  width: 800px;
  margin: 0 auto;
}
.box-group {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
  background-color: #f0f0f0;
  border-radius: 10px;
  height: 200px;
}
.small-box {
  margin-top: 15px;
  width: 120px;
  height: 130px;

  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.circle-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}
.number {
  font-size: 24px;
  margin: 10px 0;
}
.large-box {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
}
.large-box h2 {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}
.header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.status-content {
  display: flex;
  align-items: center;
}

.status-1 {
  color: #f21831;
}

.status-2 {
  color: #0074ff;
}

.status-0 {
  color: #999999;
}
.list-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 20px;
}
.list-item h3 {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}
</style>
