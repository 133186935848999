<template>
  <div class="background">
    <div class="container">
      <el-form :model="form" :rules="rules" ref="formRef">
        <el-row>
          <el-col :span="18">
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button
                type="primary"
                @click="sendCode"
                :disabled="isCounting || isSending"
              >
                {{ isCounting ? `${count}秒后重试` : '发送验证码' }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="code">
          <el-input
            v-model="form.code"
            placeholder="请输入6位验证码"
            maxlength="6"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { ref, onUnmounted, defineOptions } from 'vue';
// import { ElForm, ElFormItem, ElInput, ElButton, ElRow, ElCol } from 'element-plus';

defineOptions({
  name: 'LoginView'
});

const form = ref({
  mobile: '',
  code: ''
});

const rules = {
  mobile: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
    { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
  ],
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { pattern: /^\d{6}$/, message: '验证码必须为6位数字', trigger: 'blur' }
  ]
};

const formRef = ref(null);
const isCounting = ref(false);
const isSending = ref(false);
const count = ref(60);
const intervalId = ref(null);

const sendCode = () => {
  formRef.value.validateField('mobile', (valid) => {
    if (valid) {
      return;
    }
    isSending.value = true;
    setTimeout(() => {
      isSending.value = false;
      startCountdown();
    }, 1000); // 模拟发送验证码的1秒延迟
  });
};

const startCountdown = () => {
  isCounting.value = true;
  count.value = 60;
  intervalId.value = setInterval(() => {
    count.value--;
    if (count.value <= 0) {
      clearInterval(intervalId.value);
      isCounting.value = false;
    }
  }, 1000);
};

onUnmounted(() => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }
});
</script>

<style scoped>
.background {
  background-image: url('../images/loginBg.png'); /* 替换为你的背景图片路径 */
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.el-row {
  margin-bottom: 15px;
}

.el-col {
  padding: 0;
}
</style>