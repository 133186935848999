<template>
  <div>
    <div class="header">
      <div class="header-content">技术成果库</div>
    </div>
    <div class="main-container">
      <div class="tabs-section">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="技术成果" name="techResults"></el-tab-pane>
          <el-tab-pane label="技术需求" name="techNeeds"></el-tab-pane>
        </el-tabs>
      </div>

      <div class="button-section">
        <el-button @click="showDialog" type="primary">{{
          buttonLabel
        }}</el-button>
      </div>

      <div class="search-section" v-if="activeTab === 'techResults'">
        <el-input
          v-model="searchText"
          placeholder="搜索相关名称或领域"
          style="width: 800px; height: 40px"
          @keyup.enter="handleSearch"
        ></el-input>
      </div>
      <div class="search-section" v-else-if="activeTab === 'techNeeds'">
        <el-input
          v-model="searchText1"
          placeholder="搜索相关需求名称或领域"
          style="width: 800px; height: 40px"
          @keyup.enter="handleSearch"
        ></el-input>
      </div>
      <div class="summary-box" v-if="activeTab === 'techResults'">
        <img src="../assets/achLogo.png" alt="技术成果" class="summary-image" />
        <span class="summary-text">技术成果</span>
        <span class="summary-count">{{ total }}项</span>
      </div>
      <div class="summary-box" v-if="activeTab === 'techNeeds'">
        <img src="../assets/ach1.png" alt="技术需求" class="summary-image" />
        <span class="summary-text">技术需求</span>
        <span class="summary-count">{{ total1 }}项</span>
      </div>
      <div class="list-container">
        <div v-if="activeTab === 'techResults'" class="list-content">
          <div v-for="item in techResults" :key="item.id" class="list-item">
            <p class="name">{{ item.name }}</p>
            <p class="info-item">
              <img src="../assets/ach1.png" alt="技术领域" class="item-icon" />
              技术领域: {{ item.area }}
            </p>
            <p class="info-item">
              <img src="../assets/ach2.png" alt="所属机构" class="item-icon" />
              所属机构: {{ item.institution }}
            </p>
            <p class="info-item">
              <img src="../assets/ach3.png" alt="转化方式" class="item-icon" />
              转化方式: {{ item.transform }}
            </p>
            <p class="info-item">
              <img src="../assets/ach4.png" alt="所处阶段" class="item-icon" />
              所处阶段: {{ item.stage }}
            </p>
            <el-button type="primary" class="view-btn">查看详情</el-button>
          </div>
          <!-- 分页组件 -->
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            v-model:current-page="currentPage"
            @update:current-page="handlePageChange"
            style="margin-top: 20px; margin-left: 190px"
          ></el-pagination>
        </div>
        <div v-else-if="activeTab === 'techNeeds'" class="list-content">
          <!-- 技术需求内容 -->
          <div v-for="item in techNeeds" :key="item.id" class="list-item">
            <p class="name">{{ item.name }}</p>
            <p class="info-item">
              <img src="../assets/ach1.png" alt="技术领域" class="item-icon" />
              技术领域: {{ item.area }}
            </p>
            <p class="info-item">
              <img src="../assets/ach2.png" alt="所属机构" class="item-icon" />
              所属机构: {{ item.institution }}
            </p>
            <p class="info-item">
              <img src="../assets/ach3.png" alt="转化方式" class="item-icon" />
              转化方式: {{ item.transform }}
            </p>
            <p class="info-item">
              <img src="../assets/ach4.png" alt="所处阶段" class="item-icon" />
              所处阶段: {{ item.stage }}
            </p>
            <el-button type="primary" class="view-btn">查看详情</el-button>
          </div>
          <!-- 分页组件 -->
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total1"
            v-model:current-page="currentPage1"
            @update:current-page="handlePageChange1"
            style="margin-top: 20px; margin-left: 190px"
          ></el-pagination>
        </div>
      </div>

      <!-- 弹层 -->
      <el-dialog
        v-model="dialogVisible"
        :title="'新增' + (activeTab === 'techResults' ? '成果' : '需求')"
      >
        <el-form :model="form" label-width="120px">
          <el-form-item label="名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="领域">
            <el-input v-model="form.area"></el-input>
          </el-form-item>
          <el-form-item label="机构">
            <el-input v-model="form.institution"></el-input>
          </el-form-item>
          <el-form-item label="转化方式">
            <el-input v-model="form.transform"></el-input>
          </el-form-item>
          <el-form-item label="所处阶段">
            <el-input v-model="form.stage"></el-input>
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="form.desc" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="dialogVisible = false"
              >确定</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script setup>
import { ref,  onMounted, watch,computed } from "vue";
import { getAchieveList } from "../api/achieve.js";

const activeTab = ref("techResults");
const searchText = ref("");
const searchText1 = ref("");
const total = ref(0);
const currentPage = ref(1);
const techResults = ref([]);
const total1 = ref(0);
const currentPage1 = ref(1);
const techNeeds = ref([]);

// 模拟技术需求数据
techNeeds.value = [
  {
    id: 1,
    name: "技术需求1",
    area: "领域1",
    institution: "机构1",
    transform: "方式1",
    stage: "阶段1",
  },
  {
    id: 2,
    name: "技术需求2",
    area: "领域2",
    institution: "机构2",
    transform: "方式2",
    stage: "阶段2",
  },
  // 添加更多假数据
];
total1.value = techNeeds.value.length;

// 获取成果列表
async function getList() {
  const res = await getAchieveList({
    page: currentPage.value,
    size: 10,
    key: searchText.value,
  });
  if (res.data.list) {
    techResults.value = res.data.list;
    total.value = res.data.total;
  }
}

// 获取技术需求列表（模拟）
function getList1() {
  // 模拟分页
  const startIndex = (currentPage1.value - 1) * 10;
  const endIndex = startIndex + 10;
  techNeeds.value = techNeeds.value.slice(startIndex, endIndex);
  total1.value = techNeeds.value.length;
}

// 分页
const handlePageChange = (newPage) => {
  currentPage.value = newPage;
  getList();
};

const handlePageChange1 = (newPage) => {
  currentPage1.value = newPage;
  getList1();
};

// 搜索
function handleSearch() {
  if (activeTab.value === "techResults") {
    currentPage.value = 1;
    getList();
  } else if (activeTab.value === "techNeeds") {
    currentPage1.value = 1;
    getList1();
  }
}

// 监听activeTab变化
watch(activeTab, (newValue) => {
  if (newValue === "techResults") {
    currentPage.value = 1;
    getList();
  } else if (newValue === "techNeeds") {
    currentPage1.value = 1;
    getList1();
  }
});

// 初始化加载
onMounted(() => {
  if (activeTab.value === "techResults") {
    getList();
  } else if (activeTab.value === "techNeeds") {
    getList1();
  }
});

// // 新增按钮相关
const buttonLabel = computed(() =>
  activeTab.value === "techResults" ? "新增成果" : "新增需求"
);
const dialogVisible = ref(false);
const form = ref({
  name: "",
  area: "",
  institution: "",
  transform: "",
  stage: "",
  desc: "",
});

const showDialog = () => {
  dialogVisible.value = true;
};
</script>
<style scoped>
.header {
  background-color: blue;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  font-size: 24px;
}

.main-container {
  width: 800px;
  margin: 20px auto;
}

.tabs-section {
  display: flex;
  justify-content: center;
}

.el-tabs >>> .el-tabs__item {
  margin: 0 20px;
  font-size: 18px;
}

.search-section {
  margin: 20px 0;
}

.summary-box {
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summary-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.summary-text {
  font-size: 18px;
  font-weight: bold;
}

.summary-count {
  font-size: 18px;
  color: blue;
  margin-left: 10px;
  font-weight: 600;
}

.list-container {
  margin-top: 20px;
  width: 100%;
}

.list-item {
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.item-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.view-btn {
  margin-top: auto;
  align-self: center;
  margin-top: 10px;
}
.button-section {
  margin: 20px 0;
  text-align: right;
}
</style>
