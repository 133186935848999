<template>
  <div class="quiz-container">
    <div v-for="(item, index) in question" :key="index" class="question">
      <p class="question-title">{{ item.title }}</p>
      <div class="options">
        <span
          v-for="(option, optionIndex) in item.option"
          :key="optionIndex"
          @click="selectOption(item.id, option.option_item)"
          :class="{ selected: answer[item.id] === option.option_item }"
        >
          {{ option.option_item }}. {{ option.option_value }}
        </span>
      </div>
    </div>
    <div class="button-group">
      <button @click="submit" class="submit-btn">提交</button>
      <button @click="cancel" class="cancel-btn">取消</button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getEvaDetail, evaSubmit } from "../api/evaluation.js";

const question = ref([]);
const route = useRoute();
const router = useRouter();
const id = ref(route.query.id);
const answer = ref({});

async function getDetail() {
  if (id.value) {
    const res = await getEvaDetail({ id: id.value });
    question.value = res.data.question;
    
  }
}

function selectOption(questionId, optionItem) {
  answer.value[questionId] = optionItem;
}

async function submit() {
  // 检查是否有未回答的题目
  const unanswered = question.value.find((q) => !answer.value[q.id]);
  if (unanswered) {
    alert("请回答所有题目");
    return;
  }

  // 将answer对象转换成指定的数组格式
  const answerArray = Object.keys(answer.value).map((qId) => ({
    id: parseInt(qId, 10),
    user_answer: answer.value[qId],
  }));
  // 可以在这里将answerArray发送到服务器
  const res = await evaSubmit({
    id: id.value,
    answer: JSON.stringify(answerArray),
  });
  console.log('res',res);
  alert("答题成功，等待后台审核，请等待...");
  router.back();
}
function cancel() {
  answer.value = {};
  router.back();
}

onMounted(() => {
  getDetail();
});
</script>
<style scoped>
.quiz-container {
  width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.question {
  margin-bottom: 40px;
}

.question-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.options {
  display: flex;
  flex-direction: column;
}

.options span {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
  margin-bottom: 10px;
}

.options span:hover {
  background-color: #e0e0e0;
}

.selected {
  background-color: #128bed;
  color: blue;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}

.submit-btn,
.cancel-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.submit-btn {
  background-color: #4caf50;
  color: #fff;
}

.submit-btn:hover {
  background-color: #45a049;
}

.cancel-btn {
  background-color: #f44336;
  color: #fff;
}

.cancel-btn:hover {
  background-color: #d32f2f;
}
</style>
