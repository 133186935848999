import axios from 'axios';
// 创建 Axios 实例
//  const baseURL = 'https://ny.syshunying.cn';//线上地址

const baseURL = 'https://www.syshunying.cn';//线下地址
const http = axios.create({
  baseURL:baseURL, // 从环境变量中读取 API 基础 URL
  timeout: 10000, // 请求超时设置
  headers: {
    'Content-Type': 'application/json',
  },
});
// 请求拦截器
http.interceptors.request.use(
  (config) => {
    // 添加认证 token
    const token = '25261d290779afc0d7984377f72ff611';
    if (token) {
      config.headers.token= `${token}`;
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  }
);
// 响应拦截器
http.interceptors.response.use(
  (response) => {
    // 处理成功响应
    if (response.status === 200) {
      return response.data; // 直接返回数据
      
    }
    return response;
  },
  (error) => {
    // 处理错误响应
    if (error.response) {
      const { status, data } = error.response;

      if (status === 500) {
        // 服务器错误处理
        console.error('服务器内部错误', data.message || '未知错误');
        // 可以添加更复杂的错误处理逻辑，比如展示全局通知
      } else if (status === 401) {
        // 未授权，可能需要跳转到登录页面
        console.error('未授权，需重新登录');
        // 可根据需要添加跳转逻辑
      } else if (status === 403) {
        // 禁止访问，可能需要显示相关提示
        console.error('禁止访问', data.message || '您没有权限访问此资源');
      } else {
        // 其他状态码处理
        console.error(`错误 ${status}:`, data.message || '未知错误');
      }
    } else {
      // 网络错误或其他错误
      console.error('请求失败:', error.message);
    }
    return Promise.reject(error);
  }
);
export default http;